export const getFlatServices = (fleetPricing, euro) => {
  const list = [
    {
      name: "Pre-Purchase Inspection",
      labor_price: 120,
      labor_hours: 1.0,
      is_canned_service: true,
    },
    {
      name: "Fuel Induction Service",
      labor_price: 190,
      labor_hours: 1.5,
      is_canned_service: true,
    },
    {
      is_canned_service: true,
      name: "Engine Air Filter",
      labor_price: euro ? 65 : 45,
      labor_hours: 0.3,
      parts: [
        {
          name: "Engine Air Filter",
          price: euro ? 49.99 : 29.99,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Drain and Fill Brake System",
      labor_hours: 0.7,
      labor_price: 110,
      parts: [
        {
          name: "Brake Fluid",
          price: 20,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Drain and Fill Cooling System",
      labor_hours: 0.7,
      labor_price: 110,
      parts: [
        {
          name: "Coolant",
          price: 20,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Drain and Fill Power Steering",
      labor_hours: 0.7,
      labor_price: 110,
      parts: [
        {
          name: "Fluid - Power Steering",
          price: 20,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Cabin Air Filter",
      labor_hours: 0.3,
      labor_price: euro ? 65 : 45,
      parts: [
        {
          name: "Cabin Air Filter",
          price: euro ? 59.99 : 39.99,
          quantity: 1,
        },
      ],
    },
    {
      is_canned_service: true,
      name: "Wiper Blades",
      labor_hours: 0.3,
      labor_price: 60.0,
      parts: [
        {
          name: "Wiper Blades",
          price: 35.0,
          quantity: 1,
        },
      ],
    },
  ];

  if (fleetPricing) {
    if (fleetPricing.fleet_pads_rotors_labor_price) {
      list.unshift({
        is_canned_service: true,
        name: "Rear Pads And Rotors",
        labor_price: fleetPricing?.fleet_pads_rotors_labor_price || 180,
        labor_hours: 1.5,
        parts: [
          {
            name: "Passenger Car Brake Kit - Rear",
            price: fleetPricing?.fleet_pads_rotors_part_price || 194.49,
            quantity: 1,
          },
        ],
      });
      list.unshift({
        name: "Front Pads And Rotors",
        labor_price: fleetPricing?.fleet_pads_rotors_labor_price || 180,
        labor_hours: 1.5,
        is_canned_service: true,
        parts: [
          {
            name: "Passenger Car Brake Kit - Front",
            price: fleetPricing?.fleet_pads_rotors_part_price || 194.49,
            quantity: 1,
          },
        ],
      });
    }

    return list;
  } else if (!euro) {
    list.unshift({
      is_canned_service: true,
      name: "Front Pads And Rotors",
      labor_price: 155,
      labor_hours: 1.5,
      parts: [
        {
          name: "Passenger Car Brake Kit - Front",
          price: 240,
          quantity: 1,
        },
      ],
    });
    list.unshift({
      is_canned_service: true,
      name: "Rear Pads And Rotors",
      labor_price: 155,
      labor_hours: 1.5,
      parts: [
        {
          name: "Passenger Car Brake Kit - Rear",
          price: 240,
          quantity: 1,
        },
      ],
    });
  }

  return list;
};

export const MEMBER_OIL = {
  free_member_service: true,
  name: "Membership Oil Change",
  is_canned_service: true,
  labor_price: 0.0,
  labor_hours: 0.3,
};

export const getRegularOil = (fleetPricing, car) => {
  if (fleetPricing) {
    const priceCol = car.european_make
      ? fleetPricing.fleet_euro_oil_change_price
      : fleetPricing.fleet_oil_change_price;
    if (!priceCol) {
      return null;
    } else {
      return {
        is_canned_service: true,
        name: "Oil Change",
        labor_price: priceCol,
        labor_hours: 0.3,
        parts: [],
      };
    }
  }

  return {
    is_canned_service: true,
    name: "Oil Change",
    labor_price: 90,
    labor_hours: 0.3,
    parts: [],
  };
};
