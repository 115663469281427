import { get, isEmpty } from "lodash-es";
import { compile } from "path-to-regexp";

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phoneNumber);
};

export const isApiCalling = (type) => (state) =>
  get(state.api[type], "inProgress") || false;

const processURL = (pathRegex, params) => {
  if (isEmpty(params)) {
    return pathRegex;
  }
  const toPath = compile(pathRegex, { encode: encodeURIComponent });
  return toPath(params || {});
};

export const abstractURL = (pathRegex) => (options) =>
  processURL(pathRegex, options);

export const generateRoute = (path, component, title, percent) => ({
  GENERATOR: abstractURL(path),
  PATH: abstractURL(path)(),
  COMPONENT: component,
  TITLE: title,
  PERCENT: percent,
});

export const getPartPrice = function (
  price,
  repeatCustomer,
  diagToRepairPricing,
  searchTerm = null
) {
  let marginPercentage;

  if (searchTerm === "Battery" || searchTerm === "Spark Plugs") {
    marginPercentage = 41;
  } else if (
    diagToRepairPricing &&
    searchTerm !== "Starter" &&
    searchTerm !== "Alternator"
  ) {
    if (price < 20) {
      marginPercentage = 70;
    } else if (price < 40) {
      marginPercentage = 68;
    } else if (price < 70) {
      marginPercentage = 65;
    } else if (price < 100) {
      marginPercentage = 60;
    } else if (price < 200) {
      marginPercentage = 54;
    } else if (price < 400) {
      marginPercentage = 48;
    } else if (price < 800) {
      marginPercentage = 43;
    } else if (price < 1000) {
      marginPercentage = 37;
    } else {
      marginPercentage = 27;
    }
  } else {
    if (price < 20) {
      marginPercentage = 65;
    } else if (price < 40) {
      marginPercentage = 63;
    } else if (price < 70) {
      marginPercentage = 60;
    } else if (price < 100) {
      marginPercentage = 55;
    } else if (price < 200) {
      marginPercentage = 52;
    } else if (price < 400) {
      marginPercentage = 45;
    } else if (price < 800) {
      marginPercentage = 40;
    } else if (price < 1000) {
      marginPercentage = 35;
    } else {
      marginPercentage = 25;
    }
  }

  if (repeatCustomer) {
    marginPercentage += 5;
  }

  const finalPrice = price / (1 - marginPercentage / 100);

  return parseFloat(finalPrice).toFixed(2);
};

export const getPartPriceFleet = function (price, appt) {
  let markupPercentage;

  if (appt.fleet_job) {
    if (price < 10) {
      markupPercentage = appt.fleet_pricing.ten || 47;
    } else if (price < 20) {
      markupPercentage = appt.fleet_pricing.twenty || 47;
    } else if (price < 40) {
      markupPercentage = appt.fleet_pricing.forty || 41;
    } else if (price < 70) {
      markupPercentage = appt.fleet_pricing.seventy || 33;
    } else if (price < 100) {
      markupPercentage = appt.fleet_pricing.hundred || 30;
    } else if (price < 150) {
      markupPercentage = appt.fleet_pricing.hundred_fifty || 30;
    } else if (price < 200) {
      markupPercentage = appt.fleet_pricing.two_hundred_less || 23;
    } else {
      markupPercentage = appt.fleet_pricing.two_hundred_greater || 20;
    }
  }

  const finalPrice = price / (1 - markupPercentage / 100);

  return parseFloat(finalPrice).toFixed(2);
};

export const MASTER_EPICOR_LIST = [
  ["E4WF", "4-Way Flasher"],
  ["HRBM", "AC / Heater Blower Motor"],
  ["HRAA", "AC Accumulator Assembly"],
  ["HATC", "AC Automatic Temperature Control Module"],
  ["HATS", "AC Automatic Temperature Sensor"],
  ["HACC", "AC Compressor Assembly"],
  ["HCCS", "AC Compressor Clutch Cycle Switch"],
  ["HCCC", "AC Compressor Clutch and Coil Only"],
  ["HRCA", "AC Condenser Assembly"],
  ["HREV", "AC Expansion Valve"],
  ["HHPS", "AC High Pressure Cut-Out Switch"],
  ["HACH", "AC Hose / AC Line"],
  ["HLPS", "AC Low Pressure Cut-Out Switch"],
  ["HROT", "AC Orifice Tube"],
  ["HACD", "AC Receiver Drier"],
  ["HERC", "AC System Evacuate & Recharge"],
  ["HRAC", "AC System Recharge"],
  ["HBMR", "AC/Heater Blower Relay"],
  ["HBMS", "AC/Heater Blower Switch"],
  ["EAFB", "Adjust Fan Belts"],
  ["DRAF", "Air Filter"],
  ["DAIP", "Air Injection Pump - Emissions"],
  ["DASV", "Air Switch Valve - Emissions"],
  ["BELTS", "All Vehicle Drive Belts"],
  ["ERAL", "Alternator"],
  ["ABSC", "Anti-lock Brake System (ABS) Control Unit"],
  ["FRAB", "Axle Bearing"],
  ["FRAS", "Axle Seal"],
  ["FASF", "Axle Shaft - Front Axle"],
  ["FASR", "Axle Shaft - Rear Axle"],
  ["EBUL", "Back-Up Light"],
  ["EBULS", "Back-Up Light Switch"],
  ["SBJF", "Ball Joint - Front Suspension"],
  ["SBJR", "Ball Joint - Rear Suspension"],
  ["DBRI", "Ballast Resistor - Ignition"],
  ["DBS", "Barometer Sensor - Fuel Injection"],
  ["ERBA", "Battery"],
  ["EBHD", "Battery Hold Down"],
  ["BCM", "Body Control Module"],
  ["CBRH", "Both Radiator Hoses"],
  ["BCPV", "Brake Compensator / Proportioning Valve"],
  ["BFLS", "Brake Fluid Level Sensor"],
  ["ERBL", "Brake Light"],
  ["EBLS", "Brake Light Switch"],
  ["BMC", "Brake Master Cylinder"],
  ["BPDV", "Brake Pressure Differential Valve/Switch"],
  ["BWLS", "Brake Warning Light Switch"],
  ["DRBF", "Breather Filter"],
  ["CBPH", "Bypass Hose"],
  ["CVAS", "CV Axle Shaft / Driveshaft"],
  ["FCVJ", "CV Joint"],
  ["FCVB", "CV Joint Boot"],
  ["CAFR", "Cabin Air Filter"],
  ["DCMS", "Camshaft Position Sensor (CMP)"],
  ["DCPV", "Canister Purge Valve"],
  ["DCPS", "Canister Purge Solenoid"],
  ["XRCC", "Catalytic Converter"],
  ["SCL", "Center Link"],
  ["DACV", "Check Valve - Air Injection System"],
  ["BCIA", "Clean, Inspect & Adjust Brakes"],
  ["SCAF", "Control Arm - Front Suspension"],
  ["SCAR", "Control Arm - Rear Suspension"],
  ["SCBF", "Control Arm Bushings - Front Suspension"],
  ["SCBR", "Control Arm Bushings - Rear Suspension"],
  ["CCRT", "Coolant Reservoir / Recovery Tank"],
  ["DRCS", "Coolant Temperature Sensor"],
  ["CCFA", "Cooling Fan Assembly"],
  ["ERCL", "Courtesy / Interior Light"],
  ["CPSI", "Crankshaft Position Sensor"],
  ["XCP", "Crossover Pipe"],
  ["ACCA", "Cruise Control Actuator"],
  ["ACCM", "Cruise Control Module"],
  ["ACCS", "Cruise Control Switch"],
  ["MDHB", "Damper / Harmonic Balancer"],
  ["DDIS", "Distributor - Ignition"],
  ["DRDC", "Distributor Cap"],
  ["DRDR", "Distributor Rotor"],
  ["DDVC", "Distributor Vacuum Control"],
  ["DDV", "Diverter Valve"],
  ["ADAS", "Door Ajar Switch"],
  ["ADLM", "Door Lock Motor"],
  ["ADLS", "Door Lock Switch"],
  ["ADLT", "Door Lock Timer"],
  ["FRDS", "Driveshaft"],
  ["FDF", "Driveshaft Flange"],
  ["FDY", "Driveshaft Slip Yoke"],
  ["FDSS", "Driveshaft Support Bearing"],
  ["DEFS", "Early Fuel Evaporation Solenoid"],
  ["DEFV", "Early Fuel Evaporation Valve"],
  ["CEFM", "Electric Fan Motor - Cooling System"],
  ["CEFR", "Electric Fan Relay - Cooling System"],
  ["DCMF", "Electronic Control Module - Fuel Inject."],
  ["MREM", "Engine Mounts"],
  ["ENGS", "Engine Sensors"],
  ["MRTS", "Engine Torque Strut"],
  ["DTUP", "Engine Tune Up"],
  ["DECE", "Evaporation Canister Element"],
  ["XRCL", "Exhaust Clamp"],
  ["DEGS", "Exhaust Gas Recirculation (EGR) Solenoid"],
  ["DEGR", "Exhaust Gas Recirculation (EGR) Valve"],
  ["XRHA", "Exhaust Hanger"],
  ["XRMA", "Exhaust Manifold"],
  ["XRMG", "Exhaust Manifold Gasket"],
  ["XREP", "Exhaust Pipe"],
  ["XEP", "Extension Pipe"],
  ["HRFB", "Fan Belt - AC System"],
  ["CRFB", "Fan Belt - Cooling System"],
  ["ERFB", "Fan Belt - Electrical System"],
  ["DRFB", "Fan Belt - Emission System"],
  ["SRFB", "Fan Belt - Power Steering System"],
  ["CFBL", "Fan Blade - Cooling System"],
  ["CRFC", "Fan Clutch"],
  ["CRFP", "Freeze / Frost Plugs"],
  ["BFRD", "Front Brake Rotors / Drums"],
  ["BFDC", "Front Disc Brake Calipers"],
  ["BFDP", "Front Disc Brake Pads"],
  ["SRSF", "Front Struts or Shocks"],
  ["DFA", "Fuel Accumulator"],
  ["DFD", "Fuel Distributor or Rail"],
  ["AFDC", "Fuel Door Cable"],
  ["DRFF", "Fuel Filter"],
  ["AFGA", "Fuel Gauge"],
  ["DRFH", "Fuel Hose"],
  ["DFIR", "Fuel Injection Pump Relay"],
  ["DRFI", "Fuel Injector"],
  ["DFIO", "Fuel Injector O-Ring"],
  ["DRFP", "Fuel Pump"],
  ["DFPR", "Fuel Pump Pressure Regulator"],
  ["DFRO", "Fuel Rail O-Ring"],
  ["DFRS", "Fuel Shut Off Relay / Solenoid"],
  ["ERFU", "Fuse"],
  ["EFLC", "Fusible Link Cable"],
  ["ERHL", "Headlight"],
  ["EAHS", "Headlight Aim"],
  ["EHDS", "Headlight Dimmer Switch"],
  ["EHLS", "Headlight Switch"],
  ["XHRV", "Heat Riser / Exchanger Valve"],
  ["CRWV", "Heater Control  / Water Valve"],
  ["CRHH", "Heater Hose(s)"],
  ["HMDM", "Heater Mode Door Motor"],
  ["EHBL", "High Brake Light"],
  ["AHRC", "Hood Release Cable"],
  ["ARHA", "Horn"],
  ["ARHR", "Horn Relay"],
  ["DISC", "Idle Speed Control Motor/Regulator - FI"],
  ["SRIA", "Idler Arm"],
  ["DRIM", "Ignition  Module"],
  ["DIA", "Ignition Amplifier"],
  ["DICS", "Ignition Cables / Plug Wires"],
  ["DIC", "Ignition Capacitor"],
  ["DRIC", "Ignition Coil"],
  ["ERIS", "Ignition Switch"],
  ["EIPL", "Instrument Panel Lights"],
  ["MIMG", "Intake Manifold Gasket"],
  ["EKS", "Knock / Detonation Sensor"],
  ["ELPB", "License Plate Light"],
  ["LLSH", "Lift Support - Hood"],
  ["LLST", "Lift Support - Trunk"],
  ["CRHL", "Lower Radiator Hose"],
  ["DMAP", "Manifold Air Pressure (MAP) Sensor - Emissions"],
  ["EMLB", "Marker Light"],
  ["DMAF", "Mass Air Flow (MAF) Sensor"],
  ["DMCS", "Mixture Control Solenoid"],
  ["ENBC", "Negative Battery Cable"],
  ["ENSS", "Neutral Safety Switch"],
  ["FS", "Oil Change"],
  ["MOPG", "Oil Pan Gasket"],
  ["AOPG", "Oil Pressure Gauge"],
  ["AOPL", "Oil Pressure Light"],
  ["MOPU", "Oil Pressure Sender Unit"],
  ["MROP", "Oil Pump"],
  ["MOPS", "Oil Pump Seal"],
  ["DOSE", "Oxygen Sensor - Emissions"],
  ["DPCV", "PCV Valve"],
  ["BPWB", "Pack Wheel Bearings"],
  ["BPBC", "Park Brake Cable"],
  ["EPBS", "Park Brake Switch"],
  ["ERPL", "Park Light"],
  ["EPLL", "Park Light Lens"],
  ["DPUC", "Pick-Up Coil"],
  ["SPR", "Pitman Arm"],
  ["MPG", "Plenum Gasket"],
  ["EPBC", "Positive Battery Cable"],
  ["ARPA", "Power Antenna"],
  ["BPBB", "Power Brake Booster"],
  ["SHPH", "Power Steering Hi-Pressure Hose"],
  ["SPSP", "Power Steering Pump"],
  ["SSRH", "Power Steering Return Hose"],
  ["CRAD", "Radiator"],
  ["CRRC", "Radiator Cap"],
  ["BRBL", "Rear Brake Linings"],
  ["BRRD", "Rear Brake Rotors / Drums"],
  ["ARDR", "Rear Defroster Relay"],
  ["ARDS", "Rear Defroster Switch"],
  ["BRDC", "Rear Disc Brake Calipers"],
  ["BRDP", "Rear Disc Brake Pads"],
  ["SRSR", "Rear Struts or Shocks"],
  ["BRWC", "Rear Wheel Cylinders"],
  ["ARWS", "Rear Window Washer Switch"],
  ["ARWM", "Rear Wiper Motor"],
  ["LRCF", "Release Cable - Fuel Door"],
  ["LRCH", "Release Cable - Hood"],
  ["LRCT", "Release Cable - Trunk"],
  ["DRIR", "Reluctor"],
  ["ASBC", "Seat Belt Control Module / Switch"],
  ["ASBW", "Seat Belt Warning Switch"],
  ["APSM", "Seat Motor - Power"],
  ["APSS", "Seat Switch - Power"],
  ["ESBT", "Serpentine Belt Tensioner"],
  ["ASMM", "Side Mirror Motor"],
  ["DRSP", "Spark Plugs"],
  ["ASCA", "Speedometer Cable"],
  ["ASHA", "Speedometer Head"],
  ["TSS", "Speedometer Seal"],
  ["SSBF", "Stabilizer Bar - Front Suspension"],
  ["SSBR", "Stabilizer Bar - Rear Suspension"],
  ["ERSM", "Starter Motor"],
  ["ERSS", "Starter Solenoid"],
  ["SCD", "Steering Coupling Disc"],
  ["SSMB", "Strut Mount Bearing"],
  ["RSBL", "Sway / Stabilizer Bar End Link / Bushing - Front"],
  ["ATAC", "Tachometer Head"],
  ["ERTL", "Tail Light"],
  ["ETLL", "Tail Light Lens"],
  ["ATGL", "Temperature Gauge / Light"],
  ["CTSU", "Temperature Sender Unit"],
  ["CRTM", "Thermostat"],
  ["CRTH", "Thermostat Housing"],
  ["DTPS", "Throttle Position Sensor - Emissions"],
  ["DTPC", "Throttle Positioner - Carburetor"],
  ["STRE", "Tie-Rod Ends"],
  ["STCU", "Tilt Steering Column U-Joint"],
  ["TRTM", "Transmission Mount"],
  ["ETSF", "Turn Signal Flasher"],
  ["ETSS", "Turn Signal Switch"],
  ["FUJC", "Universal Joint - Center"],
  ["FUJF", "Universal Joint - Front"],
  ["FUJR", "Universal Joint - Rear"],
  ["FUJA", "Universal Joints - All"],
  ["CRHU", "Upper Radiator Hose"],
  ["MVCG", "Valve Cover Gaskets"],
  ["SPECS", "Vehicle Specifications, Capacities & Torque"],
  ["DVSS", "Vehicle Speed Sensor (VSS)"],
  ["EVR", "Voltage Regulator"],
  ["CRWP", "Water Pump"],
  ["WABS", "Wheel Anti-lock Brake System (ABS) Sensor"],
  ["BWB", "Wheel Bearings"],
  ["DWOT", "Wide Open Throttle Switch/Valve"],
  ["APWM", "Window Lift Motor - Power"],
  ["WREG", "Window Regulator"],
  ["AWMS", "Window Switch - Power"],
  ["AWWH", "Windshield Washer Hose / Nozzles"],
  ["AWLS", "Windshield Washer Level Sensor"],
  ["AWWP", "Windshield Washer Pump"],
  ["AWWR", "Windshield Washer Reservoir"],
  ["AWSW", "Windshield Washer Switch"],
  ["AWWM", "Windshield Wiper Motor"],
  ["AWWS", "Windshield Wiper Switch"],
  ["ARWB", "Wiper Blades"],
];

export const isValidVIN = (vin) => {
  if (typeof vin !== "string" || vin.length !== 17) {
    return false; // VIN must be a string of length 17
  }

  const invalidChars = /[IOQ]/g;
  if (invalidChars.test(vin)) {
    return false; // VIN cannot contain 'I', 'O', or 'Q'
  }

  return true;
};
