import callApi, { callApiV2 } from "./api_base";

import { buildFormData } from "./utils";

export const apiGetAppointment = async (id, is_encrypted) => {
  return callApi({
    url: `/appointments/${id}`,
    method: "GET",
    params: { is_encrypted },
  });
};

export const apiGetEstimate = async (appointment_id) => {
  return callApi({
    url: `/appointments/estimate/${appointment_id}`,
    method: "GET",
  });
};

export const apiGetAdminAvailability = (id) => {
  return callApiV2({
    url: `/appointments/appointment_availability/${id}`,
    method: "GET",
  });
};

export const apiGetAdminAsc = (id) => {
  return callApiV2({
    url: `/appointments/asc/${id}`,
    method: "GET",
  });
};

export const apiGetAdminZipAvailability = (zip) => {
  return callApiV2({
    url: `/appointments/zip_availability/${zip}`,
    method: "GET",
  });
};

export const apiGetAdminMktAvailability = (mkt) => {
  return callApiV2({
    url: `/appointments/market_availability/${mkt}`,
    method: "GET",
  });
};

export const apiAdminBookJob = (id, data) => {
  return callApiV2({
    url: `/appointments/admin_book/${id}`,
    method: "POST",
    data,
  });
};

export const apiAdminCreateJob = (data) => {
  return callApiV2({
    url: "/appointments/admin_create",
    method: "POST",
    data,
  });
};

export const getAppointmentMessages = async (id, retriever) => {
  return callApiV2({
    url: `/app_messages/${id}`,
    method: "GET",
    params: { retriever },
  });
};

export const sendAppointmentMessages = async (app_message) => {
  return callApiV2({
    url: `/app_messages`,
    method: "POST",
    data: buildFormData(app_message, "app_message"),
  });
};

export const apiGetCannedServices = async (id) => {
  return callApiV2({
    url: `/canned_services?user_id=${id}`,
    method: "GET",
  });
};
