import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import moment from "moment";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import {
  apiAdminBookJob,
  apiGetAdminAvailability,
  apiGetAdminMktAvailability,
  apiGetAdminZipAvailability,
} from "../../../_apis";
import { availableDiv } from "../components/shared";
import BookModal from "./components/BookModal";
import JobDescriptionCard from "./components/JobDescriptionCard";
import SearchInputs from "./components/SearchInputs";
import DayButton from "./components/DayButton";
import {
  createDayArray,
  pushCurrentAppointments,
  pushSchedule,
  sortScheduled,
} from "./utils";

const AdminCalendar = ({ appointment_id, admin_user_id }) => {
  const [jobId, setJobId] = useState();
  const [zip, setZip] = useState();
  const [exactAddress, setExactAddress] = useState();
  const [availability, setAvailability] = useState([]);
  const [daysAvailable, setDaysAvailable] = useState([]);
  const [availableMechanics, setAvailableMechanics] = useState([]);
  const [isDirty, setIsDirty] = useState();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [jobDescription, setJobDescription] = useState();
  const [appointment_time, setAppointmentTime] = useState();
  const [mechanic_id, setMechanicId] = useState();
  const [success, setSuccess] = useState();
  const [hasCC, setHasCC] = useState(false);
  const [mkt, setMkt] = useState();
  const [error, setError] = useState();
  const [aptNumber, setAptNumber] = useState();
  const [arrivalInstructions, setArrivalInstructions] = useState();
  const [checkedPlan, setCheckedPlan] = useState();
  const [alreadyHasTime, setAlreadyHasTime] = useState();
  const [fleet, setFleet] = useState();
  const [scheduled, setScheduled] = useState();
  const [filteredScheduled, setFilteredScheduled] = useState();
  const [mechanicToFilter, setMechanicToFilter] = useState();
  const [mechanicScheduledNames, setMechanicScheduledNames] = useState([]);

  const today = moment();
  const [currentDay, setCurrentDay] = useState(today);
  const [minDay, setMinDay] = useState(moment(today).toDate());

  const days = createDayArray(minDay);

  const isThereAvailable = (val) => {
    return daysAvailable.filter((day) =>
      moment(day, "YYYY-MM-DD").isSame(moment(val), "day")
    );
  };

  const isScheduledAppt = (val) => {
    if (!scheduled || typeof scheduled !== "object") {
      return false;
    }

    const formattedDate = moment(val).format("YYYY-MM-DD");

    if (!formattedDate) {
      return false;
    }

    return scheduled[formattedDate] !== undefined;
  };

  const searchJobId = () => {
    if (jobId) {
      apiGetAdminAvailability(jobId).then((res) => {
        if (res.errors) {
          setAvailability([]);
          setDaysAvailable([]);
          setIsDirty(true);
          setError(res.errors.join(", "));
        } else {
          const availableDays = pushSchedule(res.mechanics);
          const currentScheduled = pushCurrentAppointments(res.mechanics);

          setScheduled(currentScheduled);
          setHasCC(res.cc_exists);
          setExactAddress(res.address);
          setDaysAvailable(availableDays);
          setAvailability(res.mechanics);
          setJobDescription(res.description);
          setIsDirty(true);
          setFleet(res.fleet);

          if (
            res.description.appointment_day &&
            res.description.appointment_time &&
            res.description.mechanic_id
          ) {
            setCurrentDay(moment(res.description.appointment_day));
            setAppointmentTime(res.description.appointment_time);
            setMechanicId(res.description.mechanic_id);
            setAlreadyHasTime(true);
          } else {
            setAlreadyHasTime();
          }
        }
      });
    }
  };

  const searchZip = () => {
    apiGetAdminZipAvailability(zip).then((res) => {
      if (res.errors) {
        setAvailability([]);
        setDaysAvailable([]);
        setIsDirty(true);
        setError(res.errors.join(", "));
      } else {
        const availableDays = pushSchedule(res.mechanics);
        const currentScheduled = pushCurrentAppointments(res.mechanics);

        setScheduled(currentScheduled);
        setDaysAvailable(availableDays);
        setAvailability(res.mechanics);
        setIsDirty(true);
      }
    });
  };

  const searchMkt = () => {
    apiGetAdminMktAvailability(mkt).then((res) => {
      if (res.errors) {
        setAvailability([]);
        setDaysAvailable([]);
        setIsDirty(true);
        setError(res.errors.join(", "));
      } else {
        const availableDays = pushSchedule(res.mechanics);
        const currentScheduled = pushCurrentAppointments(res.mechanics);

        setScheduled(currentScheduled);
        setDaysAvailable(availableDays);
        setAvailability(res.mechanics);
        setIsDirty(true);
      }
    });
  };

  const bookJob = () => {
    const requestParams = {
      appointment_day: moment(currentDay).format("YYYY-MM-DD"),
      appointment_time,
      mechanic_id,
      status: "pending",
      apartment_number: aptNumber,
      hints_to_find: arrivalInstructions,
    };

    if (admin_user_id) {
      requestParams.last_admin_user_booked_by_id = admin_user_id;
    }

    if (exactAddress && Object.keys(exactAddress).includes("label")) {
      requestParams.exact_address = exactAddress.label;
    }

    const params = {
      appointment: requestParams,
    };

    if (checkedPlan) {
      params.member_price_agreed_on_before_job = checkedPlan;
    }

    apiAdminBookJob(jobId, params).then((res) => {
      if (!res.errors) {
        setSuccess(true);
      } else {
        setError(res.errors.join(", "));
      }
    });
  };

  useEffect(() => {
    if (appointment_id && !isDirty) {
      setJobId(appointment_id);
      searchJobId();
    }
  });

  useEffect(() => {
    if (currentDay && scheduled) {
      const currentScheduled =
        scheduled[moment(currentDay).format("YYYY-MM-DD")];

      if (currentScheduled) {
        setFilteredScheduled(sortScheduled(currentScheduled));

        const mechanicNames = currentScheduled.map((appt) => appt.mechanic);
        setMechanicScheduledNames([...new Set(mechanicNames)]);
      }
    }
  }, [currentDay]);

  const handleFilterChange = (e) => {
    const mechanicName = e.target.value;

    setMechanicToFilter(mechanicName);

    const current = scheduled[moment(currentDay).format("YYYY-MM-DD")];

    if (mechanicName !== "All") {
      const filtered = current.filter((appt) => appt.mechanic === mechanicName);

      setFilteredScheduled(sortScheduled(filtered));
    } else {
      setFilteredScheduled(sortScheduled(current));
    }
  };

  const Tag = (fleet, member) => {
    if (fleet === true) {
      return (
        <div
          style={{
            padding: "5px 15px",
            margin: "4px",
            backgroundColor: "#A39CEF",
            width: "max-content",
            borderRadius: 26,
          }}
        >
          <Typography style={{ fontFamily: "Inter", color: "#FFF" }}>
            Fleet
          </Typography>
        </div>
      );
    }

    if (member === true) {
      return (
        <div
          style={{
            padding: "5px 15px",
            margin: "4px",
            backgroundColor: "#16C444",
            width: "max-content",
            borderRadius: 26,
          }}
        >
          <Typography style={{ fontFamily: "Inter", color: "#FFF" }}>
            Member
          </Typography>
        </div>
      );
    }

    return (
      <div
        style={{
          padding: "5px 15px",
          margin: "4px",
          backgroundColor: "#1A5B74",
          width: "max-content",
          borderRadius: 26,
        }}
      >
        <Typography style={{ fontFamily: "Inter", color: "#FFF" }}>
          Consumer
        </Typography>
      </div>
    );
  };

  return (
    <Container style={{ marginBottom: "200px" }}>
      <SearchInputs
        mkt={mkt}
        setMkt={setMkt}
        jobId={jobId}
        setJobId={setJobId}
        zip={zip}
        setZip={setZip}
        searchZip={searchZip}
        searchMkt={searchMkt}
        searchJobId={searchJobId}
      />

      {isDirty ? (
        <>
          <JobDescriptionCard
            jobDescription={jobDescription}
            jobId={jobId}
            hasCC={hasCC}
            setHasCC={setHasCC}
            alreadyHasTime={alreadyHasTime}
            currentDay={currentDay}
            appointment_time={appointment_time}
            setConfirmationModalOpen={() => setConfirmationModalOpen(true)}
          />

          {error && <Typography color="error">{error}</Typography>}

          {availableDiv(availability)}

          {!jobDescription ||
          jobDescription.appointment_type !== "repair" ||
          jobDescription.estimate ? (
            <>
              <div>
                <div
                  style={{ gridRowGap: "1rem" }}
                  className="w-full grid grid-flow-row grid-cols-7 grid-rows-2 justify-around px-2 pt-4"
                >
                  {days.map((val, index) => {
                    return (
                      <DayButton
                        key={index}
                        val={val}
                        index={index}
                        isEvent={isThereAvailable(val)}
                        currentDay={currentDay}
                        availability={availability}
                        setAvailableMechanics={setAvailableMechanics}
                        setCurrentDay={setCurrentDay}
                        isScheduledAppt={isScheduledAppt(val)}
                      />
                    );
                  })}
                </div>
              </div>

              <div className="flex flex-row px-10 pt-2">
                <button
                  style={{ border: "none", boxShadow: "none" }}
                  className="flex-1"
                  onClick={() => {
                    setMinDay(moment(minDay).subtract(2, "weeks").toDate());
                  }}
                >
                  <ArrowBackIos color="secondary" />
                </button>
                <div className="flex flex-col">
                  <p
                    style={{ flex: 3, textAlign: "center" }}
                    className="text-lg font-inter"
                  >
                    {moment(minDay).format("MM/DD")} -{" "}
                    {moment(minDay).add(2, "weeks").format("MM/DD")}
                  </p>
                </div>
                <button
                  style={{
                    border: "none",
                    boxShadow: "none",
                  }}
                  className="flex-1"
                  onClick={() => {
                    setMinDay(moment(minDay).add(2, "weeks").toDate());
                  }}
                >
                  <ArrowForwardIos color="secondary" />
                </button>
              </div>

              {filteredScheduled && (
                <div
                  style={{
                    margin: "20px auto",
                    maxWidth: "80%",
                  }}
                >
                  <Card
                    variant="outlined"
                    style={{
                      margin: "30px 0 10px 0",
                      fontSize: "16px",
                      height: "300px",
                      overflow: "scroll",
                    }}
                  >
                    <CardHeader title="Scheduled" />

                    <CardContent>
                      <TableContainer component={Paper}>
                        {mechanicScheduledNames &&
                        mechanicScheduledNames.length > 1 ? (
                          <Typography
                            style={{ fontSize: "16px", width: "300px" }}
                          >
                            <InputLabel>Filter Mechanic</InputLabel>
                            <Select
                              fullWidth
                              value={mechanicToFilter}
                              style={{ margin: "10px 0" }}
                              onChange={handleFilterChange}
                            >
                              <MenuItem value="All">All</MenuItem>
                              {mechanicScheduledNames.map((name) => (
                                <MenuItem value={name}>{name}</MenuItem>
                              ))}
                            </Select>
                          </Typography>
                        ) : (
                          <></>
                        )}

                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ backgroundColor: "#fff" }}>
                                Job #
                              </TableCell>
                              <TableCell style={{ backgroundColor: "#fff" }}>
                                Mechanic
                              </TableCell>
                              <TableCell style={{ backgroundColor: "#fff" }}>
                                Customer
                              </TableCell>
                              <TableCell style={{ backgroundColor: "#fff" }}>
                                Appointment Time
                              </TableCell>
                              <TableCell style={{ backgroundColor: "#fff" }}>
                                Labor (hrs)
                              </TableCell>
                              <TableCell style={{ backgroundColor: "#fff" }}>
                                Type
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredScheduled.map((appt) => (
                              <TableRow key={appt.id}>
                                <TableCell>
                                  <a href={`/admin/appointments/${appt.id}`}>
                                    Job #{appt.id}
                                  </a>
                                </TableCell>
                                <TableCell>{appt.mechanic}</TableCell>
                                <TableCell>{appt.customer}</TableCell>
                                <TableCell>{appt.appointment_time}</TableCell>
                                <TableCell>{appt.labor} hr(s)</TableCell>
                                <TableCell>
                                  <Tag
                                    fleet={appt.fleet_job}
                                    member={appt.member}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </div>
              )}

              <div
                style={{
                  margin: "20px auto",
                  maxWidth: "80%",
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    marginTop: "30px",
                    fontSize: "20px",
                  }}
                >
                  Availability
                </Typography>
                {availableMechanics.map((m) => {
                  const availableTimes =
                    m.schedule[moment(currentDay).format("YYYY-MM-DD")];

                  if (!availableTimes) {
                    return <></>;
                  }

                  return (
                    <Accordion>
                      <AccordionSummary
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            flex: 10,
                            fontSize: "18px",
                            fontWeight: 800,
                          }}
                        >
                          {m.name} ({m.level} Tech)
                        </Typography>
                        <span style={{ flex: 4, fontSize: "14px" }}>
                          ({availableTimes.length} available time slots)
                        </span>
                      </AccordionSummary>

                      <AccordionDetails
                        style={{
                          maxHeight: "200px",
                          overflow: "scroll",
                          padding: "10px",
                        }}
                      >
                        {availableTimes.map((time) => {
                          return (
                            <div
                              style={{
                                margin: "5px 0",
                                borderBottom: "1px solid #ddd",
                                paddingBottom: "2px",
                                display: "flex",
                              }}
                            >
                              {jobId && (
                                <Button
                                  onClickCapture={() => {
                                    setAppointmentTime(time);
                                    setMechanicId(m.id);
                                    setConfirmationModalOpen(true);
                                  }}
                                  style={{ flex: 1 }}
                                  onClick={() => {}}
                                >
                                  Book This Time
                                </Button>
                              )}
                              <Typography style={{ flex: 1 }}>
                                {time}
                              </Typography>
                            </div>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}

                {availableMechanics.length === 0 && (
                  <Typography style={{ textAlign: "center" }}>
                    No slots available on {moment(currentDay).format("MM/DD")}
                  </Typography>
                )}
              </div>
            </>
          ) : (
            <Typography color="error">
              This is a repair job with no estimate. Write up the estimate and
              approve it before booking.
            </Typography>
          )}
        </>
      ) : (
        <></>
      )}

      <BookModal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onSubmit={bookJob}
        success={success}
        error={error}
        jobId={jobId}
        exactAddress={exactAddress}
        setExactAddress={setExactAddress}
        fleet={fleet}
        hasCC={hasCC}
        setHasCC={setHasCC}
        aptNumber={aptNumber}
        setAptNumber={setAptNumber}
        arrivalInstructions={arrivalInstructions}
        setArrivalInstructions={setArrivalInstructions}
        setCheckedPlan={setCheckedPlan}
        checkedPlan={checkedPlan}
        currentDay={currentDay}
        appointment_time={appointment_time}
      />
    </Container>
  );
};

export default AdminCalendar;
