import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  CircularProgress,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Divider,
  FormHelperText,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useParams } from "react-router";
import { apiGetAppointment } from "../../../_apis";
import TakePayment from "./components/TakePayment";
import callApi, { callApiV2 } from "../../../_apis/api_base";
import { gaHit } from "../../../_helpers/tracking";
import AfterPaidModal from "./components/AfterPaidModal";
import { currencyFormatter } from "./utility";
import { BILLINGS, MEMBER_PLANS } from "../../../_helpers/membership";
import { ExpandMore } from "@material-ui/icons";
import FmcFullWidthButton from "../components/FmcFullWidthButton";
import VehicleModal from "../../Admin/AdminMembership/components/VehicleModal";
import ImageList from "../components/ImageList";
import { buildFormData } from "../../../_apis/utils";
import { apiCreateCarForCustomer } from "../../../_apis/mechanics/customers";
import DiagNoteInPayment from "./components/DiagNoteInPayment";

const STEPS = {
  DIAG_NOTE: 0,
  MEMBERSHIP: 1,
  CHARGE: 2,
  FAMILY_PLAN: 3,
  OIL_CHANGE_STICKER: 4,
  OIL_QUARTS: 5,
};

const TITLES = [
  "Diagnosis Summary",
  "Membership",
  "Charge",
  "Family Plan",
  "Oil Change Sticker",
  "Oil Quarts",
];

function Payments() {
  const { appointment_id, payment_type } = useParams();
  const [payment, setPayment] = useState({});
  const [originalPrice, setOriginalPrice] = useState();
  const [applyDiagnosticFee, setApplyDiagnosticFee] = useState(false);
  const [billing, setBilling] = useState(BILLINGS[0]);
  const [plan, setPlan] = useState(MEMBER_PLANS[0]);
  const [wantsMembership, setWantsMembership] = useState(false);
  const [success, setSuccess] = useState();
  const [errors, setErrors] = useState();
  const [appointment, setAppointment] = useState(null);
  const [submitting, setIsSubmitting] = useState(false);
  const [gaClientId, setGaClientId] = useState();
  const [open, setOpen] = useState();
  const [systemNoteId, setSystemNoteId] = useState();
  const [diagNotes, setDiagNotes] = useState({
    appointment_id,
    diagResult: "",
    nextSteps: "",
    allCodes: "",
    testsPerformed: "",
    concerns: "",
    symptoms: "",
    diagConfidence: "",
    diagConfidenceReason: "",
    fixOnSiteExplain: "",
    couldntDiagnoseExplain: "",
  });
  const [step, setStep] = useState(STEPS.MEMBERSHIP);
  const [vehicles, setVehicles] = useState([]);
  const [existingPrice, setExistingPrice] = useState();
  const [interval, setInterval] = useState({
    count: null,
    name: null,
  });
  const [membership, setMembership] = useState();
  const [allVehicles, setAllVehicles] = useState([]);
  const [creatingMembership, setCreatingMembership] = useState();
  const [creatingCar, setCreatingCar] = useState();
  const [error, setError] = useState();
  const [subError, setSubError] = useState();
  const [carAdded, setCarAdded] = useState(carAdded);
  const [oilChangePhotos, setOilChangePhotos] = useState();
  const [hasSticker, setHasSticker] = useState();
  const [vehicleOpen, setVehicleOpen] = useState();

  const applyMembership = (oldPrice, makes, plan) => {
    const discount = parseFloat(originalPrice * 0.1).toFixed(2);
    const discountedPrice = applyDiagnosticFee
      ? parseFloat(oldPrice).toFixed(2)
      : parseFloat(oldPrice - discount).toFixed(2);

    setPayment((payment) => ({
      ...payment,
      total_price: discountedPrice,
      membership_cars: makes,
      membership_price: planPrice(),
      membership_billing_interval: plan.billing_interval,
      membership_billing_count: plan.billing_count,
      membership_plan_name: plan.name,
      apply_diagnostic_fee: applyDiagnosticFee,
    }));
  };

  const selectMembershipPlan = (makes) => {
    setWantsMembership(true);

    let planToSet;

    if (!plan && billing) {
      planToSet = MEMBER_PLANS.filter((i) => i.billing === billing.label)[0];
      setPlan(planToSet);
    } else {
      planToSet = plan;
    }

    applyMembership(payment.total_price, makes, planToSet);
  };

  const removeMembershipPlan = () => {
    setWantsMembership(false);
    setPlan();

    const currentPayment = payment;

    delete currentPayment.membership_price;
    delete currentPayment.membership_billing_interval;
    delete currentPayment.membership_billing_count;
    delete currentPayment.membership_cars;

    setPayment({
      ...currentPayment,
      total_price: originalPrice,
    });
    setApplyDiagnosticFee(false);
  };

  useEffect(() => {
    const fetchAppointment = async () => {
      if (appointment_id) {
        const rsp = await apiGetAppointment(appointment_id);
        const attr = rsp.data.attributes;

        setAppointment({
          ...attr,
          id: appointment_id,
        });

        setOilChangePhotos(attr.oil_change_photos);
        setHasSticker(
          attr.oil_change_photos && attr.oil_change_photos.length > 0
        );

        const list = attr.user_cars_list.sort() || [];
        list.push("+ Add New");
        setAllVehicles(list);

        if (payment_type === "0" && !attr.diag_note_left) {
          setStep(STEPS.DIAG_NOTE);
        } else if (attr.fleet_job || !attr.credit_card_present) {
          setPlan();
          if (attr.oil_change_photos && attr.oil_change_photos.length > 0) {
            setStep(STEPS.CHARGE);
          } else {
            setStep(STEPS.OIL_CHANGE_STICKER);
          }
        } else if (attr.member_job) {
          setStep(STEPS.FAMILY_PLAN);

          const membership = attr.membership;

          if (membership) {
            setMembership(membership);
            setVehicles(membership.vehicles);
            setExistingPrice(membership.price);
            setInterval({
              ...interval,
              count: membership.interval_count,
              name: membership.interval,
            });
          }
        }

        let price;
        if (parseInt(payment_type) === 1) {
          if (attr.member_job) {
            price = attr.estimate?.approved_member_price || 0;
          } else {
            price = attr.estimate?.approved_price || 0;
          }
        } else if (attr.appointment_type === "ppi") {
          price = attr.ppi_price;
        } else {
          price = attr.diagnosis_price;
        }

        setPayment({
          appointment_id,
          payment_type: parseInt(payment_type) === 1 ? "estimate" : "diagnosis",
          total_price: price,
        });
        setOriginalPrice(price);

        setGaClientId(attr.google_client_id);
      }
    };
    fetchAppointment();
  }, [appointment_id]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    setSuccess();
    setErrors();

    if (oilChangePhotos && oilChangePhotos.length > 0 && !hasSticker) {
      payment.oil_change_photos = oilChangePhotos;
    }

    callApi({
      url: "/payments",
      method: "POST",
      data: buildFormData(payment, "payment"),
    })
      .then((res) => {
        setIsSubmitting(false);
        if (res.errors) {
          setErrors(res.errors.join(". "));
        } else {
          setSystemNoteId(res.system_note_id);

          let productName;

          if (appointment.estimate) {
            productName = Object.keys(appointment.estimate.services).join(", ");
          } else {
            productName = appointment.appointment_type;
          }

          gaHit(
            gaClientId,
            res.total_price,
            res.id,
            appointment.user_id,
            productName
          );

          if (res.subscription_result && res.subscription_result.errors) {
            setSubError(
              `The customer has been charged for the service but not signed up for the membership due to the error below:\n${res.subscription_result.errors.join(
                ", "
              )}`
            );

            setTimeout(() => {
              setOpen(true);
            }, 10000);
          } else {
            setOpen(true);
          }
        }
      })
      .catch((e) => {
        setIsSubmitting(false);
        setErrors("Unable to charge customer. Please contact the office.");
      });
  };

  const releaseAuth = () => {
    setIsSubmitting(true);
    setSuccess();
    setErrors();

    callApi({
      url: `/payments/${appointment_id}/release-auth`,
      method: "POST",
      data: {
        payment_type,
      },
    })
      .then((res) => {
        setIsSubmitting(false);
        setSuccess("Charge refunded. You can charge again");
      })
      .catch((e) => {
        setIsSubmitting(false);
        setErrors("Unable to charge customer. Please contact the office.");
      });
  };

  const totalPrices = (memberFee = 0) => {
    let total = 0;

    total += originalPrice;

    return {
      member: total * 0.9 + memberFee,
      non_member: total,
    };
  };

  const planPrice = () => {
    if (vehicles.length === 0 || !plan) {
      return;
    }

    const len = vehicles.length;

    return 1 * plan.price + (len - 1) * 150;
  };

  const planPriceExisting = () => {
    const len = vehicles.length;

    const price = existingPrice;

    let ogPrice =
      membership.price / (1 + (membership.vehicles.length - 1) * 0.5);

    const result = 1 * ogPrice + (len - 1) * (ogPrice * 0.5);

    if (
      interval.name == "year" &&
      (!membership || membership.interval !== "year")
    ) {
      const yearlyTotal = price * len * (12 / membership.interval_count);

      result = yearlyTotal * 0.8;
    }

    return parseFloat(result).toFixed(2);
  };

  const createCar = (data) => {
    setCreatingCar(true);

    apiCreateCarForCustomer(data)
      .then((res) => {
        if (!res.errors) {
          const cars = res.data.attributes.user_cars_list;

          setVehicles([...vehicles, cars.slice(-1)[0]]);

          const list = cars.sort() || [];
          list.push("+ Add New");

          setAllVehicles(list);

          setCarAdded(true);

          setTimeout(() => {
            setCarAdded();
          }, 4000);
        }
      })
      .finally(() => setCreatingCar());
  };

  return (
    <Box pt={15} pb={15}>
      <Container>
        {appointment ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">{TITLES[step]}</Typography>

              {step === STEPS.DIAG_NOTE && (
                <DiagNoteInPayment
                  diagNotes={diagNotes}
                  setDiagNotes={setDiagNotes}
                  estimate={appointment.estimate}
                  nextStep={() => {
                    setStep(
                      appointment.member_job
                        ? STEPS.FAMILY_PLAN
                        : STEPS.MEMBERSHIP
                    );
                  }}
                  appointment_id={appointment_id}
                />
              )}

              {step == STEPS.MEMBERSHIP && (
                <Box className="my-3">
                  <Box className="my-3">
                    <Typography>
                      Please show {appointment.customer_name} the membership and
                      pricing, and make an effort to secure the sale!
                    </Typography>

                    <Accordion className="my-3" variant="outlined">
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>View Benefits</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>- FREE unlimited oil changes</Typography>
                        <Typography>- 10% off all services</Typography>
                        <Typography>
                          - Optional OBD 2 device for real-time health tracking
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>

                  <Divider />

                  <Box className="my-6">
                    <Typography variant="h5">Pricing</Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell style={{ color: "#16C444" }}>
                              Member
                            </TableCell>
                            <TableCell>Non-Member</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Service Price</TableCell>
                            <TableCell>
                              {applyDiagnosticFee
                                ? currencyFormatter.format(originalPrice)
                                : currencyFormatter.format(originalPrice * 0.9)}
                            </TableCell>

                            <TableCell>
                              {currencyFormatter.format(originalPrice)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Member Fee</TableCell>
                            <TableCell>
                              {!plan || vehicles.length === 0
                                ? "-"
                                : currencyFormatter.format(planPrice())}
                            </TableCell>
                            <TableCell>-</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Total
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {applyDiagnosticFee
                                ? currencyFormatter.format(
                                    totalPrices(plan ? planPrice() : 0)
                                      .non_member
                                  )
                                : currencyFormatter.format(
                                    totalPrices(plan ? planPrice() : 0).member
                                  )}
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              {currencyFormatter.format(
                                totalPrices(plan ? planPrice() : 0).non_member
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  {allVehicles && (
                    <FormControl fullWidth className="my-3">
                      <Autocomplete
                        multiple
                        options={allVehicles}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Add Vehicles"
                            variant="outlined"
                          />
                        )}
                        onChange={(_, newValue) => {
                          if (newValue.includes("+ Add New")) {
                            setVehicleOpen(true);
                          } else {
                            setVehicles(newValue);
                          }
                        }}
                        value={vehicles || []}
                      />
                    </FormControl>
                  )}

                  {appointment.appointment_type === "diagnosis" && (
                    <FormControl fullWidth className="my-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={applyDiagnosticFee}
                            onChange={(e) => {
                              setApplyDiagnosticFee(e.target.checked);
                              applyMembership(
                                payment.total_price,
                                vehicles,
                                plan
                              );
                            }}
                            name="applyDiagnosticFee"
                            color="primary"
                          />
                        }
                        label="Apply Diagnostic Fee (for a free first membership fee)"
                      />
                    </FormControl>
                  )}

                  <FormControl fullWidth className="my-3">
                    <Button
                      style={{ width: "93%", margin: "0 auto" }}
                      variant="contained"
                      color={wantsMembership ? "error" : "primary"}
                      onClick={() => {
                        if (wantsMembership) {
                          removeMembershipPlan();
                        } else {
                          selectMembershipPlan(vehicles);
                          if (oilChangePhotos.length > 0) {
                            setStep(STEPS.CHARGE);
                          } else {
                            setStep(STEPS.OIL_CHANGE_STICKER);
                          }
                        }
                      }}
                      disabled={vehicles.length === 0}
                    >
                      {wantsMembership ? "Remove Membership" : "Add Membership"}
                    </Button>
                    <FmcFullWidthButton
                      text="Skip"
                      variant="outlined"
                      onClick={() => {
                        setPlan();
                        if (oilChangePhotos.length > 0) {
                          setStep(STEPS.CHARGE);
                        } else {
                          setStep(STEPS.OIL_CHANGE_STICKER);
                        }
                      }}
                    />
                  </FormControl>
                </Box>
              )}

              {step === STEPS.FAMILY_PLAN && (
                <Box className="my-3">
                  <Box className="my-3">
                    <Typography>
                      {appointment.customer_name} is already a member, if you
                      add an additional vehicle to their plan, you'll earn a
                      bonus of $50.
                    </Typography>
                  </Box>
                  <Box style={{ margin: "10px" }}>
                    <Box style={{ margin: "10px auto", width: "100%" }}>
                      <FormHelperText>Price</FormHelperText>
                      {vehicles.length > 0 ? (
                        <Typography color="primary">
                          <span style={{ fontWeight: "bold" }}>
                            ${planPriceExisting()}
                          </span>{" "}
                          / {interval.count == 1 ? "" : `${interval.count} `}
                          {interval.name}
                        </Typography>
                      ) : (
                        <Typography color="error">
                          Select vehicles for price!
                        </Typography>
                      )}
                    </Box>

                    <FormControl style={{ margin: "10px auto", width: "100%" }}>
                      <Autocomplete
                        multiple
                        options={allVehicles}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Add Vehicles"
                            variant="outlined"
                          />
                        )}
                        onChange={(_, newValue) => {
                          if (newValue.includes("+ Add New")) {
                            setVehicleOpen(true);
                          } else {
                            setVehicles(newValue);
                          }
                        }}
                        value={vehicles || []}
                      />

                      {carAdded && (
                        <Typography className="my-3" color="green">
                          Car successfully added
                        </Typography>
                      )}
                    </FormControl>
                    <br />
                    <FormControl style={{ margin: "10px auto", width: "100%" }}>
                      <Button
                        disabled={
                          vehicles.length === 0 ||
                          creatingCar ||
                          creatingMembership ||
                          success
                        }
                        onClick={() => {
                          setCreatingMembership(true);

                          const membership_params = {
                            price_sold: planPriceExisting(),
                            billing_interval: interval.name,
                            billing_count: interval.count,
                            vehicles,
                            upsold_to_family_plan_by_mechanic_id:
                              appointment.mechanic?.id,
                          };

                          callApiV2({
                            url: `/memberships/${membership.id}`,
                            method: "PUT",
                            data: {
                              membership: membership_params,
                            },
                          })
                            .then((e) => {
                              if (e.errors) {
                                setError(e.errors.join(", "));
                              } else {
                                setSuccess(true);
                                setPlan();

                                setTimeout(() => {
                                  if (oilChangePhotos.length > 0) {
                                    setStep(STEPS.CHARGE);
                                  } else {
                                    setStep(STEPS.OIL_CHANGE_STICKER);
                                  }
                                }, 4000);
                              }
                            })
                            .finally(() => {
                              setCreatingMembership();
                            });
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Update Membership
                      </Button>

                      <FmcFullWidthButton
                        disabled={creatingMembership || success}
                        text="Skip"
                        variant="outlined"
                        onClick={() => {
                          setPlan();
                          if (oilChangePhotos.length > 0) {
                            setStep(STEPS.CHARGE);
                          } else {
                            setStep(STEPS.OIL_CHANGE_STICKER);
                          }
                        }}
                      />

                      <Box style={{ margin: "10px" }}>
                        {error && (
                          <Typography color="error">{error}</Typography>
                        )}
                        {success && (
                          <Typography color="green">
                            Membership edited
                          </Typography>
                        )}
                      </Box>
                    </FormControl>
                  </Box>
                </Box>
              )}

              {step === STEPS.OIL_CHANGE_STICKER && (
                <Box className="my-6">
                  <Typography className="my06">
                    Place an oil change sticker on the customer's vehicle and
                    take a photo for verification. If you have a gift box, place
                    it somewhere in the photo.
                    <span className="text-fmc-red italic"> * </span>
                  </Typography>

                  <Box className="my-6">
                    <ImageList
                      value={oilChangePhotos || []}
                      onChange={(d) => setOilChangePhotos(d)}
                      required
                    />
                  </Box>

                  <FmcFullWidthButton
                    disabled={oilChangePhotos.length == 0}
                    text="Next"
                    variant="contained"
                    onClick={() => {
                      if (appointment.ask_for_quarts) {
                        setStep(STEPS.OIL_QUARTS);
                      } else {
                        setStep(STEPS.CHARGE);
                      }
                    }}
                  />

                  {!appointment.fleet_job && (
                    <FmcFullWidthButton
                      text="Back"
                      variant="outlined"
                      onClick={() => {
                        if (appointment.member_job) {
                          setStep(STEPS.FAMILY_PLAN);
                        } else {
                          setStep(STEPS.MEMBERSHIP);
                        }
                      }}
                    />
                  )}
                </Box>
              )}

              {step === STEPS.CHARGE && (
                <TakePayment
                  payment={payment}
                  applyDiagnosticFee={applyDiagnosticFee}
                  handleSubmit={handleSubmit}
                  isSubmitting={submitting}
                  paymentType={payment_type}
                  member={appointment.member_job}
                  freeDiag={appointment.member_free_diag}
                  goBack={() => {
                    if (hasSticker) {
                      setStep(STEPS.MEMBERSHIP);
                    } else if (appointment.ask_for_quarts) {
                      setStep(STEPS.OIL_QUARTS);
                    } else {
                      setStep(STEPS.OIL_CHANGE_STICKER);
                    }
                  }}
                  plan={planPrice()}
                  removeMembership={removeMembershipPlan}
                  estimate={appointment.estimate}
                  fleetNetTerms={appointment.fleet_net_terms}
                />
              )}

              {errors &&
              errors.includes(
                "The payment could not be captured because the requested capture amount is greater than"
              ) ? (
                <>
                  <Typography className="my-1">
                    You are trying to charge an amount greater than what was
                    authorized.
                    <Typography
                      className="cursor-pointer text-blue-600"
                      onClick={releaseAuth}
                    >
                      First click here to release the authorization.
                    </Typography>
                    Then you can charge again.
                  </Typography>
                </>
              ) : (
                <Typography className="text-fmc-red my-1">{errors}</Typography>
              )}

              {success && (
                <Typography className="text-fmc-dark-green my-1">
                  {success}
                </Typography>
              )}

              {subError && (
                <Typography className="text-fmc-red my-1">
                  {subError}
                </Typography>
              )}

              <AfterPaidModal
                open={open}
                onClose={() =>
                  (window.location.href = `/mechanic/job/${appointment_id}`)
                }
                payment_type={payment_type}
                job_id={appointment_id}
                system_note_id={systemNoteId}
                partsTravel={appointment.parts_travel_time}
                fleet={appointment.fleet_job}
              />
            </Grid>
          </Grid>
        ) : (
          <Box style={{ padding: "20px", textAlign: "center" }}>
            <CircularProgress />
          </Box>
        )}

        {step === STEPS.OIL_QUARTS && (
          <Box className="my-6">
            <Box className="my-3">
              <FormControl fullWidth className="my-3">
                <InputLabel id="oil-supply-label">
                  What oil did you use?
                </InputLabel>
                <Select
                  labelId="oil-supply-label"
                  id="oil-supply"
                  variant="outlined"
                  onChange={(e) =>
                    setPayment({
                      ...payment,
                      oil_supplier: e.target.value,
                    })
                  }
                >
                  {["O'Reilly", "Valvoline"].map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box className="my-3">
              <Typography className="my06">
                How many quarts of oil did you use?
              </Typography>

              <FormControl fullWidth className="my-3">
                <TextField
                  label="Quarts"
                  variant="outlined"
                  type="number"
                  value={payment.oil_quarts_used}
                  onChange={(e) =>
                    setPayment({
                      ...payment,
                      oil_quarts_used: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Box>

            {payment.oil_supplier === "O'Reilly" && (
              <Box className="my-3">
                <FormControl fullWidth className="my-3">
                  <TextField
                    label="Provide a reason for using O'Reilly"
                    variant="outlined"
                    value={payment.oil_oreilly_explain}
                    onChange={(e) =>
                      setPayment({
                        ...payment,
                        oil_oreilly_explain: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Box>
            )}

            <FmcFullWidthButton
              text="Next"
              variant="contained"
              disabled={!payment.oil_quarts_used || !payment.oil_supplier}
              onClick={() => {
                setStep(STEPS.CHARGE);
              }}
            />
          </Box>
        )}

        {appointment && (
          <VehicleModal
            open={vehicleOpen}
            onClose={() => setVehicleOpen()}
            userId={appointment.user_id}
            nextSteps={createCar}
          />
        )}
      </Container>
    </Box>
  );
}

export { Payments };
