import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@material-ui/core";
import CreditCard from "./CreditCard";
import moment from "moment";

const JobDescriptionCard = ({
  jobDescription,
  jobId,
  hasCC,
  setHasCC,
  alreadyHasTime,
  currentDay,
  appointment_time,
  setConfirmationModalOpen,
}) => {
  if (!jobDescription) {
    return <></>;
  }

  return (
    <Card
      variant="outlined"
      style={{ margin: "30px 0 10px 0", fontSize: "16px" }}
    >
      <CardHeader title={jobDescription.customer_name} />
      <CardContent>
        <h2 style={{ margin: "10px 0" }}>{jobDescription.customer_phone}</h2>
        <h2 style={{ margin: "10px 0" }}>{jobDescription.problem}</h2>
        <h2 style={{ margin: "10px 0" }}>{jobDescription.car}</h2>
        <CreditCard
          appointment_id={jobId}
          text={hasCC ? "Change Credit Card" : "Add Credit Card"}
          setCC={setHasCC}
          expanded={false}
        />
        <br />
        {alreadyHasTime && (
          <>
            <Divider />
            <br />
            <h3 style={{ margin: "10px 0" }}>Current Booking</h3>
            <p>{moment(currentDay).format("YYYY-MM-DD")}</p>
            <p>{appointment_time}</p>
            <Button
              onClickCapture={setConfirmationModalOpen}
              style={{ margin: "10px 0" }}
              variant="contained"
            >
              Finish Booking
            </Button>
            <br />
          </>
        )}

        <Button
          style={{ margin: "10px 0" }}
          onClick={() => window.location.assign(`/admin/appointments/${jobId}`)}
          variant="outlined"
        >
          Go To Job
        </Button>
      </CardContent>
    </Card>
  );
};

export default JobDescriptionCard;
